<style>
    .loader {
        padding: 2em;
    }

    .loader__spinner {
        width: 50px;
        height: 50px;
        margin: 0 auto;
        border: 5px solid transparent;
        border-radius: 50%;
        border-top-color: var(--color-secondary);
        animation: loading__spinner 0.75s ease infinite;
    }

    @keyframes loading__spinner {
        from {
            transform: rotate(0turn);
        }

        to {
            transform: rotate(1turn);
        }
    }
</style>

<div class="loader">
    <div class="loader__spinner" />
</div>
