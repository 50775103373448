<style>
    .header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: var(--header-height);
        background-color: var(--color-primary);
        color: #ffffff;
        padding: 0.8em;
        font-size: 1.5rem;
        box-sizing: border-box;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
</style>

<div class="header">Kris Kringle</div>
